import * as React from "react"
import Hero from "./hero"
import Oppurtunity from "./oppurtunity"
import TeamWork from "./teamwork"
import Join from "./join"
import Relationship from "./relationship"
import Micro from "./micro"
import Experience from "./experience"
import Services from "./services"

import heroimage from "../../assets/images/cofeeroasterhero.png"
import Fredrik from "../../assets/images/Fredrik_Lo_fbergs.webp"
import f1 from "../../assets/images/icons/noun-global-3394124.svg"
import f2 from "../../assets/images/icons/noun-invoice-3394132.svg"
import f3 from "../../assets/images/icons/group-28.svg"
import f4 from "../../assets/images/icons/noun-mobile-3394126.svg"
import f5 from "../../assets/images/icons/group-39.svg"
import f6 from "../../assets/images/icons/noun-tablet-3394141.svg"
import bulb from "../../assets/images/icons/bulb.svg"
import globe from "../../assets/images/icons/globe.svg"
import thumb from "../../assets/images/icons/thumb.svg"
import market from "../../assets/images/icons/market.svg"
import time from "../../assets/images/icons/time.svg"
import communication from "../../assets/images/icons/invention.svg"
import chart4 from "../../assets/images/icons/chart.svg"
import Origin from "./origin"

const RoasterPage = ({ pageContext: { translate } }) => {
  const dataOppurtunity = [
    {
      icon: globe,
      title: translate.t?.global_movement,
      description: translate.t?.access_new_highend,
    },
    {
      icon: thumb,
      title: translate.t?.advantage,
      description: translate.t?.competitive_advantage,
    },
    {
      icon: bulb,
      title: translate.t?.efficiency,
      description: translate.t?.supply_chain_efficiency,
    },
  ]

  const dataTeam = [
    {
      icon: time,
      title: translate.t?.time_investment,
      description: translate.t?.spend_time_understanding_values,
    },
    {
      icon: communication,
      title: translate.t?.reinvention,
      description: translate.t?.participate_improve_community,
    },
    {
      icon: market,
      title: translate.t?.marketing_communication,
      description: translate.t?.spread_word_mission_transparency,
    },
    {
      icon: chart4,
      title: translate.t?.estate_attributes,
      description: translate.t?.roasters_adopt_onboard,
    },
  ]

  const dataService = [
    {
      title: translate.t?.estate_brands,
      description: translate.t?.help_coffee_producers,
      img: f1,
      details: [
        translate.t?.inspired_by_vineyard,
        translate.t?.save_future_coffee,
      ],
    },
    {
      title: translate.t?.new_business_opportunities,
      description: translate.t?.achieve_intl_recognition,
      img: f2,
      details: [
        translate.t?.building_profile_on_our_platform,
        translate.t?.get_lifetime_customer_base,
      ],
    },
    {
      title: translate.t?.ec_exp,
      description: translate.t?.connect_customers_storytelling,
      img: f3,
      details: [
        translate.t?.consumers_want_to,
        translate.t?.offer_premium_priced,
      ],
    },
    {
      title: translate.t?.friendly_communication,
      description: translate.t?.our_chat_tools,
      img: f4,
      details: [
        translate.t?.our_content_user_generated,
        translate.t?.no_language_barrier,
      ],
    },
    {
      title: translate.t?.elevated_sourcing,
      description: translate.t?.get_access_undiscovered,
      img: f5,
      details: [
        translate.t?.our_unique_fantastic,
        translate.t?.build_a_sustainable_future,
      ],
    },
    {
      title: translate.t?.digital_transformation,
      description: translate.t?.increase_efficiency,
      img: f6,
      details: [
        translate.t?.from_sourcing_to_sales,
        translate.t?.moving_businesses_digital,
      ],
    },
  ]
  return (
    <>
      {
        <div className="roaster_page">
          <div className="content">
            <Hero
            pageContext={{ translate }}
              pagename={translate.t?.eraofwe_for_roasters}
              title={translate.t?.discover_new_coffee}
              description={translate.t?.manage_several_data_sources}
              btntext={translate.t?.request_demo}
              img={heroimage}
              btnLink="https://calendly.com/eraofwe/introduction-eraofwe"
            />
            <Oppurtunity
            pageContext={{ translate }}
              heading={translate.t?.new_opportunities}
              btnLink=""
              data={dataOppurtunity}
              isRoaster={true}
              model="contact"
            />
            <Origin
            pageContext={{ translate }}
              heading={translate.t?.show_others_c2c}
              description={translate.t?.get_insights_f2c}
              btnText={translate.t?.how_it_works}
              btnLink={`${
                translate.currentLang ? "/" + translate.currentLang + "/" : "/"
              }traceability/`}
            />
            <Experience
            pageContext={{ translate }}
              title={translate.t?.exp_roasters_love}
              btnText={translate.t?.view_our_community}
              btnLink={`${
                translate.currentLang ? "/" + translate.currentLang + "/" : "/"
              }community/`}
              img={Fredrik}
              name="Fredrik Nilsson"
              designation="Löfbergs"
              pagetitle=""
              description={translate.t?.public_quote_3}
            />
            <div className="services-new">
              <Services
              pageContext={{ translate }}
                title={translate.t?.benefits_for_roasters}
                description={translate.t?.eraofwe_countless_roasters}
                data={dataService}
                btnText={translate.t?.contact_us}
                btnLink={`${
                  translate.currentLang
                    ? "/" + translate.currentLang + "/"
                    : "/"
                }contact/`}
              />
            </div>
            <Micro
            pageContext={{ translate }}
              pagename={translate.t?.specialty_roaster}
              title={translate.t?.from_estate_straight_mr}
              description={translate.t?.eraofwe_helps_source_gc}
            />
            <Relationship pageContext={{ translate }} />
            <div className="facilitator estate">
              <TeamWork
              pageContext={{ translate }}
                heading={translate.t?.great_teamwork_commitment}
                description=""
                btnText={translate.t?.download_whitepaper}
                btnLink=""
                modal="download"
                data={dataTeam}
              />
            </div>
            <div className="joiner-second">
              <Join pageContext={{ translate }} />
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default RoasterPage
