import * as React from "react"
import MicroBox from "./microbox"

import micro1 from "../../assets/images/mic1.jpg"
import micro2 from "../../assets/images/mic2.jpg"
import micro3 from "../../assets/images/mic3.jpg"

const Micro = ({
  pagename,
  title,
  description,
  data,
  pageContext: { translate },
}) => {
  const contentData = data ?? [
    {
      img: micro1,
      title: translate.t?.platform_access,
      description: translate.t?.onboard_mr_eraofwe,
    },
    {
      img: micro2,
      title: translate.t?.they_buy_from_you,
      description: translate.t?.let_mr_source_inventory,
    },
    {
      img: micro3,
      title: translate.t?.fast_delivery,
      description: translate.t?.deliver_greenbeans,
    },
  ]
  return (
    <>
      {
        <div className="micro">
          <div className="content">
            <div className="title">
              <h2>{pagename}</h2>
              <h3>{title}</h3>
              <p>{description}</p>
            </div>
            <div className="micro-box">
              {contentData?.map((content, counter) => (
                <div key={counter} className="micro-box-item">
                  <MicroBox
                    img={content.img}
                    count={counter + 1}
                    heading={content.title}
                    description={content.description}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Micro
