import * as React from "react"
import { useState } from "react"
import apiImage from "../../assets/images/api.svg"
import buildingRelationship from "../../assets/images/building-relationship.gif"
import dataConsumerInsights from "../../assets/images/data_consumer_insights.gif"
import orderPlaced from "../../assets/images/order-placed.gif"
import relationshipMr from "../../assets/images/relationship-mr.gif"
import canvasimg3 from "../../assets/images/shop.jpg"
import Whitepaper from "../models/whitepaper"
const Relationship = ({ isMicroRoaster, pageContext: { translate } }) => {
  const [active, setActive] = useState("box1")
  return (
    <>
      {
        <div className="relationship">
          <div className="content">
            <h2>{translate.t?.be_part_beyond_trade}</h2>
            <div className="pointer">
              <div className="left">
                <div className="complete">
                  <div
                    className={active === "box1" ? "active box" : "box"}
                    onClick={e => setActive("box1")}
                  >
                    <h3>{translate.t?.building_relationships}</h3>
                    <h4>
                      {isMicroRoaster
                        ? translate.t?.access_global_24hr_delivery
                        : translate.t?.get_access_world_premium}
                    </h4>
                    <p>{translate.t?.get_access_couldnt_before}</p>
                  </div>
                  {active === "box1" && (
                    <div className="mobile-img">
                      <img
                        src={
                          isMicroRoaster ? relationshipMr : buildingRelationship
                        }
                        alt="Relationship"
                      />
                    </div>
                  )}
                  <div
                    className={active === "box2" ? "active box" : "box"}
                    onClick={() => setActive("box2")}
                  >
                    <h3>
                      {isMicroRoaster
                        ? translate.t?.in_app_integration
                        : translate.t?.knowledge_driven}
                    </h3>
                    <h4>
                      {isMicroRoaster
                        ? translate.t?.integrate_with_your_brands
                        : translate.t?.data_consumer_insights}
                    </h4>
                    <p>{translate.t?.edu_materials}</p>
                  </div>
                  {active === "box2" && (
                    <div className="mobile-img">
                      <img
                        src={isMicroRoaster ? apiImage : dataConsumerInsights}
                        alt={isMicroRoaster ? "API" : "Consumer Insights"}
                      />
                    </div>
                  )}
                  <div
                    className={active === "box3" ? "active box" : "box"}
                    onClick={() => setActive("box3")}
                  >
                    <h3>
                      {isMicroRoaster
                        ? translate.t?.end_user_experience
                        : translate.t?.ecommerce}
                    </h3>
                    <h4>
                      {isMicroRoaster
                        ? translate.t?.order_management
                        : translate.t?.ecom_b2b_b2c}
                    </h4>
                    <p>
                      {isMicroRoaster
                        ? translate.t?.check_ratings_reviews
                        : translate.t?.streamline_process}
                    </p>
                  </div>
                  {active === "box3" && (
                    <div className="mobile-img">
                      <img
                        src={isMicroRoaster ? orderPlaced : canvasimg3}
                        alt={isMicroRoaster ? "Order Placed" : "Shop"}
                      />
                    </div>
                  )}
                </div>
                <Whitepaper
                  pageContext={{ translate }}
                  btnText={translate.t?.book_a_demo}
                  styler={"greenbtn"}
                  title={translate.t?.contact_form}
                  description={translate.t?.contact_form_description}
                  type="contact"
                  submitBtnText={translate.t?.submit}
                />
              </div>
              <div className="right">
                {active === "box1" && (
                  <img
                    src={isMicroRoaster ? relationshipMr : buildingRelationship}
                    alt="Relationship"
                  />
                )}
                {active === "box2" && (
                  <img
                    src={isMicroRoaster ? apiImage : dataConsumerInsights}
                    alt={isMicroRoaster ? "API" : "Consumer Insights"}
                  />
                )}
                {active === "box3" && (
                  <img
                    src={isMicroRoaster ? orderPlaced : canvasimg3}
                    alt={isMicroRoaster ? "Order Placed" : "Shop"}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Relationship
