import * as React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import RoasterPage from "../components/roaster"
import languages from "../utils/enums/languages"

const Roaster = ({ location, pageContext: { translate } }) => (
  <Layout
    secondary
    path="partners"
    location={location}
    pageContext={{ translate }}
    SEO={({ data }) => (
      <SEO
        title={data.t?.discover_new_coffee}
        description={data.t?.manage_several_data_sources}
        keywords={data.t?.keywords_roaster}
        languages={[
          ...languages.map(l => {
            return {
              hrefLang: l || 'en',
              href: `${process.env.GATSBY_SITE_URL}${
                l ? "/" + l + "/" : "/"
              }coffee-roaster/`,
            }
          }),
          {
            hrefLang: "x-default",
            href: `${process.env.GATSBY_SITE_URL}/coffee-roaster/`,
          },
        ]}
      />
    )}
  >
    <>
      {
        <>
          <RoasterPage pageContext={{ translate }} />
        </>
      }
    </>
  </Layout>
)

export default Roaster
